<template>
  <div>
    <apexcharts
      type="donut"
      :height="height"
      :width="width"
      :options="chartOptions"
      :series="chartSeries"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "DonutChart",
  components: {
    apexcharts: VueApexCharts,
  },
  props: {
    chartSeries: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      width: "100%",
      chartOptions: {
        labels: ["Kompeten", "Tidak Kompeten"],
        colors: ["#00B633", "#FF4A11"],
        plotOptions: {
          pie: {
            donut: {
              size: "60%",
            },
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
