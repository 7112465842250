<template>
  <div
    class="card rounded-xl h-100"
    :style="{ 'background-color': secondColor }"
  >
    <div class="d-flex p-5 gap-3 justify-content-between">
      <div class="col-8">
        <div class="d-flex flex-column p-0 gap-1">
          <h4>{{ title }}</h4>
          <div class="text-grey">{{ greyNote }}</div>
          <h1>{{ desc }}</h1>
          <div class="text-primary">{{ note }}</div>
        </div>
      </div>
      <div
        class="rounded-circle-container"
        :style="{ 'background-color': color }"
      >
        <i
          class="bi bi-bar-chart-fill bg-white rounded-1"
          :style="{ color: color }"
        ></i>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "CardColorFlex",
  props: {
    title: String,
    desc: String,
    note: String,
    greyNote: String,
    color: String,
    secondColor: String,
  },
  components: {},
});
</script>
<style lang="scss" scoped>
.rounded-circle-container {
  width: 36px;
  height: 36px;
  border-radius: 50%; /* Makes the container circular */
  display: flex;
  justify-content: center;
  align-items: center;
}

.bi.bi-bar-chart-fill {
  font-size: 17px;
}
</style>
